import React, { useState } from 'react';
import { graphql } from 'gatsby';
import HtmlHead from '../components/html-head';
import { BuilderComponent } from '@builder.io/react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { PageData } from '../types';
import '../builder-settings';
import '../styles/index.css';

const Index: React.FC<PageData> = resp => {
    const [useNav, setUseNav] = useState(true);
    // const seoData = resp.data.allContentfulPageSeoData.edges[0].node;

    return (
        <>
            {/* <HtmlHead
                title={seoData.title}
                metaDescription={seoData.metaDescription.metaDescription}
                canonicalLink={seoData.canonicalLink}
                openGraphImage={seoData.openGraphImage.file.url}
            /> */}
            {useNav && <Navbar />}
            <BuilderComponent model="page" contentLoaded={content => setUseNav(content.useNav)} />
            {useNav && <Footer />}
        </>
    );
};

export default Index;

// export const query = graphql`
//     query HomeQuery {
//         allContentfulPageSeoData(filter: { pagePath: { eq: "/" } }) {
//             edges {
//                 node {
//                     title
//                     canonicalLink
//                     metaDescription {
//                         metaDescription
//                     }
//                     openGraphImage {
//                         file {
//                             url
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;
